<template>
  <div class="searchBox">
    <input type="text" placeholder="请输入关键字" class="searchBox-input" />
    <div class="searchBox-btn">
      <i class="searchBox-btn-icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightSearch",
};
</script>

<style lang="scss" scoped>
.searchBox {
  @include flex-start;
  width: 681px;
  height: 48px;
  margin: 20px 0;
  border: 1px solid #ff6900;
  border-radius: 10px;
  &-btn {
    @include flex-between(center);
    width: 62px;
    height: 100%;
    background-color: #ff6900;
    border: 1px solid #ff6900;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    transform: translateX(1px);
    &-icon {
      width: 20px;
      height: 20px;
      background-image: url("~assets/images/search.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  &-input {
    border: none;
    padding-left: 20px;
    height: calc(100% - 2px);
    border-radius: 10px 0 0 10px;
    width: 599px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }
}
</style>
