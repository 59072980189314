<template>
  <div class="sortBox">
    <div class="sortBox-left">
      <div
        class="sortBox-item activeTop"
        v-for="(sort, index) in sortList"
        :key="index"
      >
        <div class="sortBox-item-text">{{ sort.name }}</div>
        <i class="sortBox-item-icon top"></i>
      </div>
    </div>
    <div class="sortBox-right">
      <span class="num">{{ num }}</span>
      <slot name="txt">个商品</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightSortBox",
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    num: {
      default: 112,
    },
  },
  data() {
    return {
      sortList: this.itemList,
    };
  },
};
</script>

<style lang="scss" scoped>
.sortBox {
  @include flex-between;
  height: 46px;
  background: $color-text-white;
  margin-top: 21px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  &-left {
    @include flex-start;
    .sortBox-item {
      @include flex-start;
      margin-right: 38px;
      cursor: pointer;
      &-icon {
        width: 9px;
        height: 13px;
        background-size: cover;
        background-image: url("~assets/images/sort-ash.png");
        background-repeat: no-repeat;
        margin-left: 5px;
      }
      &.activeTop {
        .sortBox-item-icon {
          background-image: url("~assets/images/sort-top.png");
        }
      }
      &.activeBottom {
        .sortBox-item-icon {
          background-image: url("~assets/images/sort-bottom.png");
        }
      }
    }
  }
  &-right span {
    margin-right: 5px;
    color: #ff6900;
  }
}
</style>
