<template>
  <div class="copyright-banner" v-show="show">
    <el-carousel trigger="click" arrow="never" height="280px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img
          @click="imgClick(index)"
          class="img"
          width="100%"
          height="280px"
          :src="item.imgUrl"
          alt=""
        />
      </el-carousel-item>
    </el-carousel>

    <registrationDialog
      @registerClick="registerClick"
      ref="registrationDialog"
    />
    <Header ref="headerRegister" />
  </div>
</template>

<script>
import registrationDialog from "components/Exclusive-registration/registrationDialog.vue";
import Header from "components/header/Header.vue";
export default {
  components: {
    registrationDialog,
    Header,
  },
  name: "CopyrightBanner",
  props: {
    showBanner: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: this.showBanner,
      bannerList: this.images,
    };
  },

  methods: {
    registerClick(val) {
      this.$refs["headerRegister"].register_flag = val;
    },
    imgClick(i) {
      if (i == 0) {
        this.$refs["registrationDialog"].isDialogShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__indicators {
  left: 50%;
  transform: translateX(-50%);
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.img {
  cursor: pointer;
}
::v-deep .wm-antiplagiarism-header {
  width: 0;
  height: 0;
  overflow: hidden;
  min-width: auto;
}
</style>
